import React, {useEffect} from 'react';
import './Finance.css'
import './Table/Table.css'
import {Stack} from "react-bootstrap";
import logo from "../image/logo.webp";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FinanceTable from "./Table/Table";
import {useAuthHeader, useAuthUser} from "react-auth-kit";
import axios from "axios";
import Moment from "moment";
import Chart from './Chart';
import './Chart.css';
import {useLocalStorage} from "../hooks/localstorage";

export function Finance() {

    const [guide, setGuide] = useLocalStorage('guide', '');
    const [year, setYear] = useLocalStorage('year', '2024');
    const [guideList, setGuideList] = React.useState([]);

    const auth = useAuthUser(null)
    const authHeader = useAuthHeader()

    useEffect(() => {
        if (auth()?.role === 'guide') {
            setGuide(auth()?.display_name);
            return
        }

        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/guides")
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    setGuideList(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, []);

    const [rows, setRows] = React.useState([]);

    // const authHeader = useAuthHeader()
    useEffect(() => {
        if (guide === '') {
            return
        }
        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/finance?guide=" + guide + "&year=" + year)
            .then((response) => {
                if (response.data && response.data.length >= 0) {
                    setRows(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, [guide, year]);

    const handleGuideChange = (event: SelectChangeEvent) => {
        setGuide(event.target.value);
    };

    const handleYearChange = (event: SelectChangeEvent) => {
        setYear(event.target.value);
    };

    const paidRows = rows.filter(row => !(row.net_amount + row.net_2nd_amount + row.invoice_amount > 0 && (Moment(row.net_payment_date).year() >= 2024 || Moment(row.invoice_payment_date).year() >= 2024 || Moment(row.net_2nd_payment_date).year() >= 2024)))
    const upcomingRows = rows.filter(row => row.net_amount + row.net_2nd_amount +  + row.invoice_amount > 0 && (Moment(row.net_payment_date).year() >= 2024 || Moment(row.invoice_payment_date).year() >= 2024 || Moment(row.net_2nd_payment_date).year() >= 2024))

    return (
        <div>
            <Stack direction="horizontal" style={{padding: '10px 0px'}} gap={1}>
                <div className="p-1 col-1">
                    <img src={logo} className="logo" alt="Emotion.club" width={50}/>
                </div>
                <div className="p-1 col-11">
                    <h2 className="text-center">Finance</h2>
                </div>
            </Stack>
            {auth()?.role === 'admin' &&
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label2">Guide</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label2"
                    id="demo-simple-select-standard2"
                    value={guide}
                    onChange={handleGuideChange}
                    label="Period"
                >
                    {guideList.map((guide, index) => {
                        return (<MenuItem key={guide} value={guide}>{guide}</MenuItem>)
                    })}
                </Select>
            </FormControl>
            }
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label2">Year</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label2"
                    id="demo-simple-select-standard2"
                    value={year}
                    onChange={handleYearChange}
                    label="Period"
                >
                    {["2024", "2025"].map((year, index) => {
                        return (<MenuItem key={year} value={year}>{year}</MenuItem>)
                    })}
                </Select>
            </FormControl>
            <div className="finance" style={{paddingBottom: '100px', marginTop: '50px'}}>
                <Chart guide={guide}></Chart>
                <FinanceTable key={1} guide={guide} rows={paidRows} isPaid={false}></FinanceTable>
                <FinanceTable key={2} guide={guide} rows={upcomingRows} isPaid={true}></FinanceTable>
            </div>
        </div>
    )
}
