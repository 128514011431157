import React, {useEffect} from 'react';
import './Chart.css'
import {useAuthHeader} from "react-auth-kit";
import axios from "axios";
import Moment from "moment";
import { BarChart } from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

export default function Chart({guide}) {

    const [earningsChart, setEarningsChart] = React.useState([]);

    const authHeader = useAuthHeader()

    useEffect(() => {
        if (guide === '') {
            return
        }
        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/earnings_chart?guide=" + guide + "&year=2024")
            .then((response) => {
                if (response.data && response.data.length >= 0) {

                    response.data.forEach(function(item){
                        item.enabled = true;
                    });
                    setEarningsChart(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, [guide]);

    let greyRule = "& .MuiBarElement-root:nth-of-type(n+"+(Moment(new Date()).format('M')-3)+")"

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [poperText, setPoperText] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.target);
    };

    const handlePopperClick = (event, d) => {
        console.log(d)
        switch(d.dataIndex) {
            case 10:
                setPoperText("Best ‘year’ result in the team")
                handleClick(event);
                break
            case 9:
                setPoperText("My average earning per tour this year")
                handleClick(event);
                break
            default:
                // setPoperText("My average earning in April based on 2 tours and 20 reviews")
                break
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const valueFormatter = (value: number | null) => `€${value}`;

    if (earningsChart.length === 0) {
        return
    }

    return (
        <div style={{ width: '100%' }}>
            <h5>🧩 €{earningsChart.filter(v => v.key === "Year")[0].value} is my average earning per tour</h5>

            <Box sx={{ flexGrow: 1 }}>
                <BarChart
                    tooltip={{ trigger: 'item' }}
                    onItemClick={(event, d) => handlePopperClick(event, d)}
                    // onTouchStart={(event, d) => console.log(d)}
                    // onAxisClick={(event, d) => {
                    //     console.log(event, d);
                    //     switch(d.axisValue) {
                    //         case 'Best':
                    //             setPoperText("Best result in the team this year")
                    //             handleClick(event);
                    //             break
                    //         case 'Year':
                    //             setPoperText("My average earning per tour this year")
                    //             handleClick(event);
                    //             break
                    //         default:
                    //             // setPoperText("My average earning in April based on 2 tours and 20 reviews")
                    //             break
                    //     }
                    // }}
                    margin={{left: 0, right: 0, top: 0, bottom: 20}}
                    dataset={earningsChart}
                    yAxis={[
                        {
                            hideTooltip: true,
                        }
                    ]}
                    xAxis={[
                        {
                            scaleType: 'band',
                            dataKey: 'key',
                            hideTooltip: true,
                            disableTicks: true,
                        }
                    ]}
                    position={"top"}
                    barLabel={(item, context) => {
                        return context.bar.height < 30 ? null : item.value?.toString();
                    }}
                    sx={{
                        "& .MuiBarElement-root": {
                            fill: "#8EC975",
                        },
                        "& .MuiChartsAxis-tickLabel": {
                            fontFamily: `"Montserrat", sans-serif`
                        },
                        "& .MuiBarLabel-root": {
                            fontFamily: `"Montserrat", sans-serif`
                        },

                        [greyRule]: {
                            fill: "#e0e0e0",
                        },
                        "& .MuiBarElement-root:nth-last-of-type(2)": {
                            fill: "#6aa84f",
                        },
                        "& .MuiBarElement-root:last-of-type": {
                            fill: "#ff6d01",
                        },
                        "& .MuiChartsTooltip-root": {
                            display: "none",
                        },
                        "& .MuiChartsAxis-directionY": {
                            display: "none",
                        },
                    }}
                    series={[{ dataKey: 'value', valueFormatter }]}
                    height={200}
                />

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 }}>{poperText}</Typography>
                </Popover>

            </Box>
        </div>
    )
}
