import React, {useState} from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import momentPlugin from '@fullcalendar/moment'
import multiMonthPlugin from '@fullcalendar/multimonth'
import listPlugin from '@fullcalendar/list';
import ReactCountryFlag from "react-country-flag"
import {Modal} from "../Modal/Modal";
import axios from 'axios';
import {useAuthHeader, useAuthUser} from "react-auth-kit";
import {Button, ButtonGroup, Container, Form, Stack} from "react-bootstrap";
import Moment from "moment";
import "./Calendar.css";
import logo from "../image/logo.webp";
import {useLocalStorage} from "../hooks/localstorage";
// import {slide as Menu} from "react-burger-menu";

export function Calendar() {
// export function Calendar({guide, guideMode}) {
    //
    // console.log("guide, guideMode", guide, guideMode)
    const auth = useAuthUser(null)
    const [isOpen, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [dateTitle, setDateTitle] = useState([]);
    const [today, setToday] = useState([]);
    const [calendarType, setCalendarType] = useState(null);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [guideList, setGuideList] = React.useState([]);

    const authHeader = useAuthHeader()
    // useEffect(() => {
    //     axios.defaults.headers.common['token'] = authHeader()
    //     axios.get("/api/guides")
    //         .then((response) => {
    //             if (response.data && response.data.length > 0) {
    //                 setGuideList(response.data)
    //             }
    //             return response.data;
    //         })
    // }, [authHeader]);

    function escFunction(event){
        if (event.key === "Escape") {
            setOpen(false)
        }
    }
    function clickListFunction(event){
        const desc_object = document.getElementsByClassName("fc-day-today")
        if (desc_object.length) {
            desc_object[0].scrollIntoView();
        }
    }

    React.useEffect(() => {

        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/guides")
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    setGuideList(response.data)
                }
                return response.data;
            })

        // console.log("useEffect")
        const desc_object = document.getElementsByClassName("fc-listMonth-button");
        if (desc_object.length) {
            desc_object[0].onclick = clickListFunction
        }
        document.addEventListener("keydown", escFunction, false);

        axios.defaults.headers.common['token'] = authHeader()
        console.log(">>> calendar useEffect")
        console.log("authHeader()", authHeader())

        const getPosts = async () => {

            console.log("getPosts isOpen", isOpen)
            if (isOpen) {
                return
            }

            console.log(">>> calendar getPosts()")
            console.log("authHeader()", authHeader())
            console.log("axios.defaults.headers.common['token']", axios.defaults.headers.common['token'])
            axios.get(`/api/calendar`)
                .then((response) => {
                    // console.log('server response:', response)

                    if (response.data && response.data.length > 0) {
                        let d = response.data.map(event => {
                            event.className = event.sell >= 13 ? "finish" : (event.sell === 0 ? "open" : "in_progress")
                            return event
                        })
                        setData(d)
                    }
                    // setData(response.data)
                    return response.data;
                })
        };

        getPosts();

        const interval = setInterval(() => {
            // console.log("aaaasdasdasdasdasd"); // Refresh data every 10 seconds
            getPosts();
        }, 60000);

        return () => {
            clearInterval(interval); // Clear the interval on component unmount
        };
        // eslint-disable-next-line
    },[isOpen])

    function loadEnvs() {
        return data.filter((event) => {
            if (guide === 'all') {
                return true
            }
            if (guide === 'RU' || guide === 'US' ) {
                return event.language === guide
            }
            return event.title === guide;
        })
    }

    function handleEventClick(e) {
        console.log('handleEventClick', e, e.event._def.extendedProps, e.event._def.publicId)
        const event = structuredClone(e.event._def.extendedProps);
        event.start = data.filter(v => v.id === e.event._def.publicId)[0].start
        event.guide = e.event._def.title
        event.publicId = e.event._def.publicId
        event.filename = e.event._def.extendedProps.filename
        setCurrentEvent(event)
        setOpen(true)
    }
    function handleEventClose(e) {
        setOpen(false)
    }

    function renderEventContent(eventInfo) {
        const cyrillicPattern = /^\p{Script=Cyrillic}+$/u;

        // console.log('eventInfo', eventInfo)
        return (
            <span key={eventInfo.event.id}>
                {guideMode === false &&
                    <span className="fc-daygrid-event-dot" style={{borderColor: eventInfo.event.borderColor, display: 'inline-block'}}></span>
                }
                <b>{eventInfo.timeText}</b>

                <span>
                    {guideMode === false ? (<>
                            <ReactCountryFlag className='emojiFlag' style={{fontSize: '1.3em'}}
                                              countryCode={eventInfo.event.extendedProps.language ? eventInfo.event.extendedProps.language : (cyrillicPattern.test(eventInfo.event.title.replace(/ /g, '')) ? 'RU' : 'US')}/> {eventInfo.event.extendedProps.tour}, {eventInfo.event.title}
                        </>
                    ) : (eventInfo.event.extendedProps.tour)
                    }
                </span>

                {guideMode === false && eventInfo.view.type === "listMonth" &&
                    <div className='slots'>
                        {[...Array(13)].map(function (x, i) {
                            return <div
                                className={'rectangle' + (eventInfo.event.extendedProps.sell > i ? ' sold' : '')}
                                key={i}></div>
                        })}
                    </div>
                }
            </span>
        )
    }
    const getCurrentMonth = (arg)  => {
        const startDate = arg.view.activeStart
        // console.log('11Current Month', arg.view.type, startDate, startDate.getMonth(), arg)
        setCalendarType(arg.view.type)
        let titleDate = ""
        if (arg.view.type === 'multiMonthYear'){
            // console.log('Current Month', startDate.getMonth() + 1, Moment(startDate).format('YYYY') )
            titleDate = Moment(startDate).format('YYYY')
            setDateTitle(Moment(startDate).format('YYYY'))
        }
        if( arg.view.type === 'dayGridMonth'){
            startDate.setDate(startDate.getDate() + 8)
            // console.log('Current Month', startDate.getMonth() + 1, Moment(startDate).format('MMMM YYYY') )
            // setDateTitle(Moment(startDate).format('MMMM YYYY'))
            titleDate = Moment(startDate).format('MMMM YYYY')
        }
        if( arg.view.type === 'listMonth'){
            // startDate.setDate(startDate.getDate() + 8)
            // console.log('Current Month', startDate.getMonth(), Moment(startDate).format('MMMM YYYY'))
            // setDateTitle(Moment(startDate).format('MMMM YYYY'))
            titleDate = Moment(startDate).format('MMMM YYYY')

        }
        setToday(Moment(startDate).format('MMMM YYYY') === Moment(new Date()).format('MMMM YYYY'))
        setDateTitle(titleDate)
    }
    const calendarRef = React.createRef();

    const [guideMode,setGuideMode] = useState(auth()?.role === 'guide')
    const [guide, setGuide] = useLocalStorage('guide', 'all');

    function handleChange(e) {
        setGuide(e.target.value);
    }

    function handleGuideMode(e) {
        setGuideMode(e.target.checked)
    }


    // function showSettings (event) {
    //     event.preventDefault();
    // }

    return (
        <div className={guideMode === true ? 'guide_mode' : ''}>
            <div className="sticky-top">
                {/*<Menu right>*/}
                {/*    <a id="home" className="menu-item" href="/">Home</a>*/}
                {/*    <a id="about" className="menu-item" href="/about">About</a>*/}
                {/*    <a id="contact" className="menu-item" href="/contact">Contact</a>*/}
                {/*    <span onClick={ showSettings } className="menu-item--small">Settings</span>*/}
                {/*</Menu>*/}

            {auth()?.role === 'admin' ? (
                <Stack direction="horizontal" style={{padding: '10px 0px'}} gap={1}>
                    <div className="p-1 col-4">
                        <img src={logo} className="logo" alt="Emotion.club" width={50}/>
                    </div>
                    <div className="p-1 col-5 justify-content-center">
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="guide mode"
                            onChange={handleGuideMode}
                        />
                        <Form.Select aria-label="Default select example" size="sm"
                                     onChange={handleChange} value={guide}>
                            <optgroup label="languages">
                                <option value="all">All</option>
                                <option value="RU">🇷🇺 Russian</option>
                                <option value="US">🇺🇸 English</option>
                            </optgroup>
                            <optgroup label="guides">
                                {guideList.map((guide, index) => {
                                    return (<option value={guide}>{guide}</option>)
                                })}
                            </optgroup>
                        </Form.Select>
                    </div>
                    <div className="p-1 col-3">
                    </div>

                </Stack>
            ) : (
                <Stack direction="horizontal" style={{padding: '10px 0px'}} gap={1}>
                    <div className="p-1 col-1">
                        <img src={logo} className="logo" alt="Emotion.club" width={50}/>
                    </div>
                    <div className="p-1 col-11">
                        <h2 className="text-center">tour calendar</h2>

                    </div>
                </Stack>
            )}

            {currentEvent &&
                <Modal key={Math.random()} isOpen={isOpen} onClose={handleEventClose} guideMode={guideMode} currentEvent={currentEvent}></Modal>
            }
            <Container style={{padding: 0}} className="fc fc-media-screen11 fc-direction-ltr fc-theme-standard22">
                <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr">
                    <div className="fc-toolbar-chunk">
                        {/*fc-today-button*/}
                        <Button className="fc-today-button fc-button fc-button-primary" variant="outline-success" onClick={(dateClickInfo) => {
                            calendarRef.current.getApi().today()
                        }} disabled={today}>today</Button>

                        <ButtonGroup className="fc-button-group" variant="dark" aria-label="Basic example">
                            <Button className="fc-prev-button fc-button fc-button-primary"
                                    variant="outline-success" onClick={(dateClickInfo) => {
                                calendarRef.current.getApi().prev()
                            }}>
                                <span className="fc-icon fc-icon-chevron-left" role="img"></span>
                            </Button>
                            <Button className="fc-next-button fc-button fc-button-primary"
                                    variant="outline-success" onClick={(dateClickInfo) => {
                                calendarRef.current.getApi().next()
                            }}><span
                                className="fc-icon fc-icon-chevron-right" role="img"></span></Button>
                        </ButtonGroup>
                    </div>
                    <div className="fc-toolbar-chunk">
                        <h2 className="fc-toolbar-title" id="fc-dom-596">{dateTitle}</h2>
                    </div>
                    <div className="fc-toolbar-chunk">
                        <div className="fc-button-group">
                            <button type="button" title="year view" onClick={(dateClickInfo) => {
                                calendarRef.current.getApi().changeView('multiMonthYear', Moment().format('YYYY-MM-01'))
                            }}
                                    className={"fc-multiMonthYear-button fc-button fc-button-primary"+(calendarType === 'multiMonthYear' ? ' fc-button-active' : '')}>year
                            </button>
                            <button type="button" title="month view" onClick={(dateClickInfo) => {
                                calendarRef.current.getApi().changeView('dayGridMonth', Moment().format('YYYY-MM-01'))
                            }}
                                    className={"fc-dayGridMonth-button fc-button fc-button-primary"+(calendarType === 'dayGridMonth' ? ' fc-button-active' : '')}>month
                            </button>
                            <button type="button" title="list view" onClick={(dateClickInfo) => {
                                calendarRef.current.getApi().changeView('listMonth', Moment().format('YYYY-MM-01'))
                            }}
                                    className={"fc-listMonth-button fc-button fc-button-primary"+(calendarType === 'listMonth' ? ' fc-button-active' : '')}>list
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
            </div>
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, momentPlugin, multiMonthPlugin, listPlugin]}
                // initialEvents={INITIAL_EVENTS}
                // weekends={false}
                // titleFormat='dddd, MMMM D, YYYY'
                datesSet={(arg) => getCurrentMonth(arg)}
                eventTimeFormat='HH:mm'
                // initialView='multiMonthYear'
                initialView='dayGridMonth'
                multiMonthMaxColumns={3}
                events={loadEnvs()}
                listDayFormat='MMMM D, dddd'
                listDaySideFormat={false}
                headerToolbar={false}
                /*headerToolbar={{
                    left: 'today prev,next',
                    center: 'title',
                    right: (auth().role === 'admin' ? 'multiMonthYear,dayGridMonth,listMonth' : 'dayGridMonth,listMonth')
                }}*/
                // eventLimit={false} // for all non-TimeGrid views
                // views={{
                //     dayGrid: {
                //         eventLimit: 6 // adjust to 6 only for timeGridWeek/timeGridDay
                //     },
                //     multiMonth: {
                //         eventLimit: 6 // adjust to 6 only for timeGridWeek/timeGridDay
                //     },
                //     multiMonthYear: {
                //         eventLimit: 6 // adjust to 6 only for timeGridWeek/timeGridDay
                //     }
                // }}
                themeSystem='bootstrap5'
                editable={true}
                contentHeight='auto'
                eventOrder='-language,start,title'
                visibleRange={{
                    start: '2024-03-01',
                    end: '2024-12-31'
                }}
                // validRange={{
                //     start: '2024-03-01',
                //     end: '2024-12-31'
                // }}
                // isHeightAuto={false}
                // forPrint={false}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
            />
        </div>
    )
}
