import React from 'react';
// import Box from '@mui/material/Box';
// import Collapse from '@mui/material/Collapse';
// import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './Table.css'
// import Collapse from "@mui/material/Collapse";
// import Box from "@mui/material/Box";
// import axios from "axios";
// import Moment from "moment/moment";
// import {useAuthHeader} from "react-auth-kit";


function Row(props: { insights: any, isPaid: boolean}, isUpcoming2: boolean) {
    const {insights} = props;
    const [open, setOpen] = React.useState(false);

    function twoDecimals(n) {
        var log10 = n ? Math.floor(Math.log10(n)) : 0,
            div = log10 < 0 ? Math.pow(10, 1 - log10) : 100;

        return Math.round(n * div) / div;
    }
    // console.log("guide", guide)
    return (
        <React.Fragment>
            <TableRow
                sx={{'& > *': {borderBottom: 'unset'}}}
                onClick={() => setOpen(!open)}
                style={{cursor: "pointer"}}
            >
                <TableCell component="th" scope="row">
                    {insights.guide}
                </TableCell>
                <TableCell align="center">{insights.stars_count && twoDecimals(insights.stars_total/insights.stars_count)}</TableCell>
                <TableCell align="center">{insights.reviews_count}</TableCell>
                <TableCell align="center">€{insights.earning_count && Math.round(insights.earning_total/insights.earning_count)}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function GuidesTable({rows, isPaid, guideList}) {

    return (
        <>
            <div style={{margin: "50px 0px 20px 0px"}}>
                <h5>Guides performance</h5>
            </div>
            {rows.length > 0 ?
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Guide</TableCell>
                                <TableCell align="center">Avg rating</TableCell>
                                <TableCell align="center">Total reviews</TableCell>
                                <TableCell align="center">Avg earning</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <Row key={row.guide} insights={row} isPaid={isPaid}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div>list empty</div>
            }
        </>
    );
}

