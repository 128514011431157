import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './Table.css'
// import axios from "axios";
import Moment from "moment/moment";
// import {useAuthHeader} from "react-auth-kit";

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
    price: number,
) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        details: [
            {
                date: '18\u00A0Mar',
                tour: 'VAT',
                service: 160,
                reviews: 30,
                onsite: -30,
                others: 0,
                total: 190,
            },
            {
                date: '17\u00A0Mar',
                tour: 'VAT',
                service: 160,
                reviews: 10,
                onsite: -10,
                others: 0,
                total: 170,
            },
            {
                date: '16\u00A0Mar',
                tour: 'VAT',
                service: 160,
                reviews: 0,
                onsite: -10,
                others: 0,
                total: 160,
            },
            {
                date: '15\u00A0Mar',
                tour: 'VAT',
                service: 160,
                reviews: 50,
                onsite: -10,
                others: 0,
                total: 210,
            },
        ],
    };
}

function Row(props: { row: ReturnType<typeof createData>, isPaid: boolean}, isUpcoming2: boolean) {
    const {row, isPaid} = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow
                sx={{'& > *': {borderBottom: 'unset'}}}
                onClick={() => setOpen(!open)}
                style={{cursor: "pointer"}}
            >
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.period}
                    {/*{Moment(row.period).format('DD')}-{Moment(row.period).format('DD') === "01" ? "15" : Moment(row.period).daysInMonth()} {Moment(row.period).format('MMM')}*/}
                </TableCell>
                <TableCell align="center">{row.due}</TableCell>
                {/*<TableCell align="center">{row.last_balance}</TableCell>*/}
                <TableCell align="center">{row.net_amount + row.net_2nd_amount + row.invoice_amount}</TableCell>
                {isPaid ?
                    <TableCell align="center" style={row.due - (row.net_amount + row.net_2nd_amount + row.invoice_amount) > 0?{color: 'red', fontWeight: '600'}:{}}>{row.due - (row.net_amount + row.net_2nd_amount + row.invoice_amount)}</TableCell>
                    :
                    <TableCell align="center">{row.due}</TableCell>
                }
            </TableRow>
            <TableRow>
                <TableCell className="collapse22" style={{paddingBottom: 0, paddingTop: 0, padding: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            {/*<Typography variant="h6" gutterBottom component="div">*/}
                            {/*    Details*/}
                            {/*</Typography>*/}
                            {row.details.filter(v => v.service > 0).length > 0 && row.details.reduce((acc, curr) => acc + curr.reviews, 0) > 0 &&
                                <caption style={{display: 'block', fontSize: '1rem'}}>
                                    Your {row.details.filter(v => v.service > 0).length} tours lead to
                                    €{row.details.reduce((acc, curr) => acc + curr.reviews, 0)} of reviews 🤩
                                </caption>
                            }
                            <Table size="small" aria-label="purchases">
                                {((Number(row.net_amount) > 0 && Moment(row.net_payment_date).year() >= 2024) ||
                                    (Number(row.invoice_amount) > 0 && Moment(row.invoice_payment_date).year() >= 2024) ||
                                        (Number(row.net_2nd_amount) > 0 && Moment(row.net_2nd_payment_date).year() >= 2024)) &&

                                    <caption>
                                        {Number(row.net_amount) > 0 && Moment(row.net_payment_date).year() >= 2024 &&
                                            <>
                                                Was paid €{row.net_amount} (net)
                                                on {Moment(row.net_payment_date).format('MMMM Do')} 👍<br/>
                                            </>
                                        }
                                        {Number(row.net_2nd_amount) > 0 && Moment(row.net_2nd_payment_date).year() >= 2024 &&
                                            <>
                                                Was paid €{row.net_2nd_amount} (net)
                                                on {Moment(row.net_2nd_payment_date).format('MMMM Do')} 👍<br/>
                                            </>
                                        }
                                        {Number(row.invoice_amount) > 0 && Moment(row.invoice_payment_date).year() >= 2024 &&
                                            <>
                                                Was paid €{row.invoice_amount} (invoice)
                                                on {Moment(row.invoice_payment_date).format('MMMM Do')} 👍<br/>
                                            </>
                                        }
                                    </caption>
                                }
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Date</TableCell>
                                        <TableCell align="center">Tours</TableCell>
                                        <TableCell align="center">Service</TableCell>
                                        <TableCell align="center">Reviews</TableCell>
                                        {/*<TableCell align="right">Others</TableCell>*/}
                                        <TableCell align="center">Total</TableCell>
                                        {row.onsite !== 0 &&
                                            <TableCell align="center">On Site</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.details.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row" align="center">
                                                {Moment(historyRow.date).format('DD MMM')}
                                            </TableCell>
                                            <TableCell align="center">{historyRow.tour}</TableCell>
                                            <TableCell align="center">{historyRow.service}</TableCell>
                                            <TableCell align="center">{historyRow.reviews}</TableCell>
                                            {/*<TableCell align="right">€{historyRow.others}</TableCell>*/}
                                            <TableCell align="center">{historyRow.total}</TableCell>
                                            {row.onsite !== 0 &&
                                                <TableCell align="center">{historyRow.onsite}</TableCell>
                                            }
                                        </TableRow>
                                    ))}

                                    <TableRow>
                                        <TableCell align="left" rowSpan={3} colSpan={1}/>
                                        <TableCell align="right" colSpan={1}>Subtotal</TableCell>
                                        <TableCell align="center">{row.details.reduce((acc, curr) => acc + curr.service, 0) }</TableCell>
                                        <TableCell align="center">{row.details.reduce((acc, curr) => acc + curr.reviews, 0) }</TableCell>
                                        <TableCell align="center">{row.details.reduce((acc, curr) => acc + curr.total, 0) }</TableCell>
                                        {row.onsite !== 0 &&
                                        <TableCell align="center">{row.details.reduce((acc, curr) => acc + curr.onsite, 0) }</TableCell>
                                        }
                                    </TableRow>
                                    {row.others !== 0 &&
                                        <TableRow>
                                            <TableCell align="left" rowSpan={3} colSpan={2}/>
                                            <TableCell align="center" colSpan={1}>Other</TableCell>
                                            <TableCell align="center">{row.others}</TableCell>
                                        </TableRow>
                                    }
                                    <TableRow>
                                        {row.others === 0 &&
                                            <TableCell align="left" rowSpan={3} colSpan={2}/>
                                        }
                                        <TableCell align="center" colSpan={1}>Total</TableCell>
                                        <TableCell align="center">{row.due}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function FinanceTable({rows, isPaid}) {

    return (
        <>
            <div style={{margin: "50px 0px 20px 0px"}}>
                    {isPaid ?
                        <h5>💰 Paid (total €{rows.reduce((acc, curr) => acc + curr.net_amount + curr.net_2nd_amount + curr.invoice_amount, 0)})</h5>
                    :
                        <h5>🐾 Upcoming (total €{rows.reduce((acc, curr) => acc + curr.due, 0)})</h5>
                    }
            </div>
            {rows.length > 0 ?
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>Period</TableCell>
                                <TableCell align="center">Amount</TableCell>
                                {/*<TableCell align="center">Previous</TableCell>*/}
                                <TableCell align="center">Paid</TableCell>
                                <TableCell align="center">Due</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <Row key={row.name} row={row} isPaid={isPaid}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div>list empty</div>
            }
        </>
    );
}
