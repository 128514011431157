import React, {useEffect} from 'react';
import './Rates.css'
import {Stack} from "react-bootstrap";
import logo from "../image/logo.webp";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useLocalStorage} from "../hooks/localstorage";
import {useAuthHeader, useAuthUser} from "react-auth-kit";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export function Rates() {

    const [guide, setGuide] = useLocalStorage('guide', '');
    const [guideList, setGuideList] = React.useState([]);

    const auth = useAuthUser(null)
    const authHeader = useAuthHeader()

    useEffect(() => {
        if (auth()?.role === 'guide') {
            setGuide(auth()?.display_name);
            return
        }

        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/guides")
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    setGuideList(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, []);

    const [rows, setRows] = React.useState([]);

    // const authHeader = useAuthHeader()
    useEffect(() => {
        if (guide === '') {
            return
        }
        axios.defaults.headers.common['token'] = authHeader()
        axios.get("/api/rates?guide=" + guide)
            .then((response) => {
                if (response.data && response.data.length >= 0) {
                    setRows(response.data)
                }
                return response.data;
            })
        // eslint-disable-next-line
    }, [guide]);

    const handleChange = (event: SelectChangeEvent) => {
        setGuide(event.target.value);
    };

    return (
        <div>
            <Stack direction="horizontal" style={{padding: '10px 0px'}} gap={1}>
                <div className="p-1 col-1">
                    <img src={logo} className="logo" alt="Emotion.club" width={50}/>
                </div>
                <div className="p-1 col-11">
                    <h2 className="text-center">Rates</h2>
                </div>
            </Stack>
            <div className="rates" style={{paddingBottom: '100px', marginTop: '50px'}}>


                <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="demo-simple-select-standard-label2">Guide</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label2"
                        id="demo-simple-select-standard2"
                        value={guide}
                        onChange={handleChange}
                        label="Period"
                    >
                        {guideList.map((guide, index) => {
                            return (<MenuItem value={guide}>{guide}</MenuItem>)
                        })}
                    </Select>
                </FormControl>

                <div style={{margin: "50px 0px 20px 0px"}}>
                    <h5>Rates</h5>
                </div>

                <TableContainer component={Paper} className="review_table">
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Guide</TableCell>
                                <TableCell align="right">Start time</TableCell>
                                <TableCell align="right">End time</TableCell>
                                <TableCell align="right">Rate (€)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.Guide}
                                    </TableCell>
                                    <TableCell align="right">{row.StartTime}</TableCell>
                                    <TableCell align="right">{row.EndTime}</TableCell>
                                    <TableCell align="right">{row.Rate}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
